.fGraphContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
.jobDetail {
  display: flex;
  width: 250px;
  position: absolute;
  top: 3%;
  left: 3%;
  opacity: 0.6;
}
.rDetail {
  display: flex;
  width: 250px;
  position: absolute;
  top: 3%;
  right: 3%;
  opacity: 0.6;
}
.fGraph {
  position: relative;
  width: 100%;
  height: 100%;
}
.graphCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.graphCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #f5f5f5;
  background-color: #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #f5f5f5;
}
.graphCard_lite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #000000;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #000000;
}
.graphCardTitle {
  font-size: 1.5em;
  font-weight: bold;
  color: #f5f5f5;
}
.graphCardTitle_lite {
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
}
.graphCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.graphCardBody {
  font-size: 0.8em;
  color: #f5f5f5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 100%;
  padding-left: 10%;
  margin-top: -25 px;
}
.graphCardBody_lite {
  font-size: 0.8em;
  color: #000000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 100%;
  padding-left: 10%;
}
